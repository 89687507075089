import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';
import { TranslationPaths } from '@/types/i18next';
import { Wrapper } from './FooterSocial.styles';
import DiscordIcon from '/public/icons/socials/discord.svg';
import FacebookIcon from '/public/icons/socials/facebook.svg';
import InstagramIcon from '/public/icons/socials/instagram.svg';
import SteamCommunityIcon from '/public/icons/socials/steam.svg';
import TwitterIcon from '/public/icons/socials/twitter.svg';
export type Social = 'facebook' | 'twitter' | 'instagram' | 'steamcommunity' | 'discord';
interface FooterSocialProps {
  social: Social;
  openInNewTab?: boolean;
}
const iconMap: Record<Social, ReactNode> = {
  facebook: <FacebookIcon />,
  instagram: <InstagramIcon />,
  twitter: <TwitterIcon />,
  discord: <DiscordIcon />,
  steamcommunity: <SteamCommunityIcon />
};
const paths: Record<Social, string> = {
  facebook: 'https://www.facebook.com/PirateSwap',
  instagram: 'https://www.instagram.com/pirateswap_official/',
  twitter: 'https://www.twitter.com/pirateswapcom',
  discord: 'https://discord.gg/Vxu8af2pcP',
  steamcommunity: 'https://steamcommunity.com/groups/pirateswap'
};
const ariaLabels: Record<Social, string> = {
  facebook: 'facebookAriaLabel',
  instagram: 'instagramAriaLabel',
  twitter: 'twitterAriaLabel',
  discord: 'discordAriaLabel',
  steamcommunity: 'steamCommunityAriaLabel'
};
export const FooterSocial = ({
  social,
  openInNewTab = true
}: FooterSocialProps) => {
  const {
    t
  } = useTranslation('footer');
  return <Wrapper href={paths[social]} target={openInNewTab ? '_blank' : '_self'} aria-label={t(ariaLabels[social] as TranslationPaths<'footer'>)} title={t(ariaLabels[social] as TranslationPaths<'footer'>)} data-sentry-element="Wrapper" data-sentry-component="FooterSocial" data-sentry-source-file="FooterSocial.tsx">
      {iconMap[social]}
    </Wrapper>;
};