import Link from 'next/link'
import styled, { css } from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GRAY, WHITE } from '@/styles/colors'
import { TIMINGS } from '@/styles/transitions'
import { goldman, montserrat } from '@/utils/fonts'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    row-gap: 16px;
  }
`

export const Label = styled.h6`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${goldman.style.fontFamily};
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  color: ${GRAY.x500};
  margin: 0;
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;

  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    row-gap: 12px;
  }
`

export const Item = styled.li`
  display: flex;
  align-items: center;
  column-gap: 16px;
`

export const Dash = styled.span`
  height: 1px;
  width: 14px;
  background-color: ${WHITE};
  opacity: 0.3;
`

const linkStyles = css`
  width: 100%;
  text-decoration: none;
  font-family: ${montserrat.style.fontFamily};
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: ${WHITE};
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
  transition: color ${TIMINGS.x02};

  &:hover {
    color: ${GRAY.x200};
  }
`

export const StyledLink = styled(Link)`
  ${linkStyles}
`

export const StyledButton = styled.button`
  border: none;
  cursor: pointer;
  background: none;
  ${linkStyles};
`
