import { css } from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'

export const generalWrapperStyles = (
  maxWidth: number,
  withVerticalOffset?: boolean
) => css`
  --padding: 12px;

  overflow-x: clip;
  min-height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns:
    minmax(var(--padding), 1fr)
    min(${maxWidth}px, 100% - (var(--padding) * 2))
    minmax(var(--padding), 1fr);

  & > * {
    grid-column: 2/3;
  }

  & > .full-width {
    grid-column: 1/-1;
  }
  @media (min-width: ${BREAKPOINTS_PX.md}) {
    --padding: 16px;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    --padding: 24px;
  }

  ${withVerticalOffset
    ? css`
        --offset: 60px;
        padding-top: var(--offset);
        padding-bottom: var(--padding);
        @media (min-width: ${BREAKPOINTS_PX.xl}) {
          --offset: 70px;
        }
        @media (min-width: ${BREAKPOINTS_PX.xxxl}) {
          --offset: 80px;
        }
      `
    : ``}
`
