import styled from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { BLUE, GRAY, LANDING_GRAY } from '@/styles/colors'
import { goldman, lato } from '@/utils/fonts'

import { commonButton } from '../Button/Button.styles'

export const OuterWrapper = styled.div<{ $backgroundColor?: string }>`
  grid-column: 1 / -1 !important;
  display: grid;
  grid-template-columns: subgrid;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor || 'transparent'};
`

export const Wrapper = styled.footer`
  grid-column: 2;
  position: relative;
  width: 100%;
  margin-top: 40px;
  padding: 24px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 24px;
  border-top: 1px solid ${GRAY.x800};
  z-index: 1;
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    margin-bottom: 48px;
    row-gap: 60px;
  }
`

export const TopSection = styled.div`
  display: grid;
  row-gap: 24px;
  grid-template-areas:
    'header'
    'settings';
  @media (min-width: ${BREAKPOINTS_PX.md}) {
    display: grid;
    grid-template-areas:
      'header settings'
      'links links';
  }
  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    grid-template-columns: auto 1fr auto;
    grid-template-areas: 'header links settings';
    column-gap: 24px;
  }
`

export const BottomSection = styled.div`
  margin: 0 -12px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  row-gap: 24px;
  background-color: ${LANDING_GRAY};
  border-radius: 6px 6px 0 0;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    margin: 0 -16px;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    margin: 0;
    flex-direction: row;
    border-radius: 6px;
  }
`

export const LinksContainer = styled.div`
  grid-area: links;
  justify-self: end;
  display: none;
  width: 100%;
  column-gap: 24px;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    display: flex;
    justify-content: space-between;
  }
  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    justify-content: space-evenly;
  }
`

export const Header = styled.div`
  grid-area: header;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    align-items: flex-start;
  }
`

export const Settings = styled.div`
  grid-area: settings;
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 12px;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    width: max-content;
    place-self: end;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    row-gap: 32px;
  }
`

export const Copyright = styled.div<{ $align?: 'start' | 'end' }>`
  font-family: ${lato.style.fontFamily};
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${GRAY.x500};

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    text-align: ${({ $align }) => $align || 'start'};
    font-size: 14px;
    line-height: 20px;
  }
`

export const Socials = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`

export const SupportButton = styled.button`
  ${commonButton};
  width: 100%;
  height: 46px;
  padding: 16px 64px;
  font-family: ${goldman.style.fontFamily};
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${BLUE.x400};
  background-color: ${BLUE.x900};
  border-radius: 4px;
`
