import { Trans, useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import Logo from 'public/icons/logo/footer.svg';
import { useAuth } from 'react-oidc-context';
import { useGetSteamProfile } from '@/hooks/query/useGetSteamProfile';
import { TranslationPaths } from '@/types/i18next';
import { toggleCrisp } from '@/utils/crisp';
import { urls } from '@/utils/urls';
import { BottomSection, Copyright, Header, LinksContainer, OuterWrapper, Settings, Socials, SupportButton, TopSection, Wrapper } from './Footer.styles';
import { FooterLinks } from './FooterLinks';
import { FooterSocial } from './FooterSocial';
export interface FooterProps {
  className?: string;
  backgroundColor?: string;
}
const DynamicLanguageSelect = dynamic(() => import('@/components/Footer/FooterLanguageSelect'), {
  ssr: false
});
const footerLinks = [{
  title: 'account',
  authLocked: true,
  items: [{
    label: 'userAccount',
    path: urls.profile,
    type: 'link'
  }]
}, {
  title: 'pirateSwap',
  items: [{
    label: 'exchanger',
    path: urls.exchanger,
    type: 'link'
  }, {
    label: 'instantSell',
    path: urls.instantSell,
    type: 'link'
  }, {
    label: 'freebies',
    path: urls.freebies,
    type: 'link'
  }]
}, {
  title: 'community',
  items: [{
    label: 'FAQ',
    path: urls.faq,
    type: 'link'
  }, {
    label: 'common:blog',
    path: urls.blog,
    type: 'link'
  }, {
    label: 'helpCenter',
    path: urls.helpCenter,
    type: 'link'
  }]
}, {
  title: 'company',
  items: [{
    label: 'common:privacyPolicy',
    path: urls.privacyPolicy,
    type: 'link'
  }, {
    label: 'common:refundsPolicy',
    path: urls.refundsPolicy,
    type: 'link'
  }, {
    label: 'common:termsOfService',
    path: urls.termsOfService,
    type: 'link'
  }]
}];
export const Footer = ({
  className,
  backgroundColor
}: FooterProps) => {
  const {
    isAuthenticated,
    isLoading
  } = useAuth();
  const {
    t
  } = useTranslation(['footer', 'common']);
  const {
    data: steamProfileData
  } = useGetSteamProfile();
  return <OuterWrapper $backgroundColor={backgroundColor} data-sentry-element="OuterWrapper" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <Wrapper className={className} data-sentry-element="Wrapper" data-sentry-source-file="Footer.tsx">
        <TopSection data-sentry-element="TopSection" data-sentry-source-file="Footer.tsx">
          <Header data-testid="footer-logo" data-sentry-element="Header" data-sentry-source-file="Footer.tsx">
            <Logo data-sentry-element="Logo" data-sentry-source-file="Footer.tsx" />
            <Socials data-sentry-element="Socials" data-sentry-source-file="Footer.tsx">
              <FooterSocial social="facebook" data-sentry-element="FooterSocial" data-sentry-source-file="Footer.tsx" />
              <FooterSocial social="twitter" data-sentry-element="FooterSocial" data-sentry-source-file="Footer.tsx" />
              <FooterSocial social="instagram" data-sentry-element="FooterSocial" data-sentry-source-file="Footer.tsx" />
              <FooterSocial social="steamcommunity" data-sentry-element="FooterSocial" data-sentry-source-file="Footer.tsx" />
              <FooterSocial social="discord" data-sentry-element="FooterSocial" data-sentry-source-file="Footer.tsx" />
            </Socials>
          </Header>

          <Settings data-sentry-element="Settings" data-sentry-source-file="Footer.tsx">
            <SupportButton onClick={() => toggleCrisp(steamProfileData?.userId)} data-sentry-element="SupportButton" data-sentry-source-file="Footer.tsx">
              support 24/7
            </SupportButton>
            {!isLoading && <DynamicLanguageSelect />}
          </Settings>
          <LinksContainer data-sentry-element="LinksContainer" data-sentry-source-file="Footer.tsx">
            {footerLinks.map(link => (!link.authLocked || isAuthenticated) && <FooterLinks key={link.title} label={String(t(link.title as TranslationPaths<'footer'>))} items={link.items.map(item => ({
            label: String(t(item.label as TranslationPaths<'footer'>)),
            path: item.path,
            type: 'link'
          }))} />)}
          </LinksContainer>
        </TopSection>
        <BottomSection data-sentry-element="BottomSection" data-sentry-source-file="Footer.tsx">
          <Copyright data-sentry-element="Copyright" data-sentry-source-file="Footer.tsx">
            <Trans suppressHydrationWarning={true} i18nKey="footer:owned" components={{
            br: <br />
          }} data-sentry-element="Trans" data-sentry-source-file="Footer.tsx" />
          </Copyright>
          <Copyright $align="end" data-sentry-element="Copyright" data-sentry-source-file="Footer.tsx">
            <Trans suppressHydrationWarning={true} i18nKey="footer:copy" components={{
            br: <br />
          }} data-sentry-element="Trans" data-sentry-source-file="Footer.tsx" />
          </Copyright>
        </BottomSection>
      </Wrapper>
    </OuterWrapper>;
};