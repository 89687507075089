import { Item, Label, List, StyledButton, StyledLink, Wrapper } from './FooterLinks.styles';
interface LinkItem {
  type: 'link';
  label: string;
  path: string;
}
interface ButtonItem {
  type: 'button';
  label: string;
  onClick: () => void;
}
interface FooterLinksProps {
  label: string;
  items: (LinkItem | ButtonItem)[];
}
export const FooterLinks = ({
  items,
  label
}: FooterLinksProps) => {
  return <Wrapper data-sentry-element="Wrapper" data-sentry-component="FooterLinks" data-sentry-source-file="FooterLinks.tsx">
      <Label data-sentry-element="Label" data-sentry-source-file="FooterLinks.tsx">{label}</Label>
      <List data-sentry-element="List" data-sentry-source-file="FooterLinks.tsx">
        {items.map((item, idx) => <Item key={idx}>
            {item.type === 'link' ? <StyledLink href={item.path}>{item.label}</StyledLink> : <StyledButton onClick={item.onClick}>{item.label}</StyledButton>}
          </Item>)}
      </List>
    </Wrapper>;
};