import { encryptUser } from '@/api/web'

export const toggleCrisp = async (userId: string | undefined) => {
  if (typeof window === 'undefined') {
    console.warn('toggleCrisp called on server')
    return
  }

  const id = userId ? await encryptUser(userId) : null

  if (
    window.$crisp &&
    typeof window.$crisp.push === 'function' &&
    typeof window.$crisp.is === 'function'
  ) {
    if (!window.$crisp.is('chat:opened')) {
      window.$crisp.push(['do', 'chat:open'])
      window.$crisp.push(['set', 'session:data', ['userId', id || 'null']])
      const sessionId = window.$crisp.get('session:identifier')
      if (sessionId) {
        window.$crisp.push(['set', 'session:data', ['sessionId', sessionId]])
      }
    } else {
      window.$crisp.push(['do', 'chat:close'])
    }
  }
}

export const closeCrisp = () => {
  if (window.$crisp && typeof window.$crisp.push === 'function') {
    window.$crisp.push(['do', 'chat:close'])
  }
}
