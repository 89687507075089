import Link from 'next/link'
import styled from 'styled-components'

import { GRAY } from '@/styles/colors'
import { TIMINGS } from '@/styles/transitions'

export const Wrapper = styled(Link)`
  display: flex;
  column-gap: 8px;
  align-items: center;
  text-decoration: none;
  color: ${GRAY.x800};
  transition: color ${TIMINGS.x01};

  &:hover {
    color: ${GRAY.x700};
  }

  & > svg {
    width: 32px;
    height: 32px;
  }
`
